import * as React from "react";
import Seo from "../components/seo";
import MainLayout from "../layouts/main";
import NotFoundContent from "../components/NotFoundContent";
import builder, { Builder, BuilderComponent } from "@builder.io/react";
import pageSignupStyles from "../templates/page-signup-form-template/styles.module.scss";
import { RegisterComponentLibrary } from "@utils/RegisterComponentLibrary";
import AlternativeHeaderSignUpForm from "@components/AlternativeHeaderSignUpForm/AlternativeHeaderSignUpForm";
import DefaultLayoutShortNav from "@layouts/DefaultLayoutShortNav";
import { RegisterComponentResourcePost } from "@utils/RegisterComponentResourcePost";
import { RegisterComponentPageBlank } from "@utils/RegisterComponentPageBlank";
import "@builder.io/widgets";
import { RegisterComponentSignForm } from "@utils/RegisterComponentSignForm";
import { RegisterHomepageLegacy } from "@components/BuilderWrapperComponents/HomepageLegacy";

type PreviewingModel = "page-resource-post" | "page" | "page-signup-form";

const componentMap = {
  "page-resource-post": () => {
    RegisterComponentResourcePost();
  },
  page: () => {
    RegisterComponentPageBlank();
    import("../libs/builder.setting");
  },
  "page-signup-form": () => {
    RegisterComponentSignForm();
  },
  default: () => {
    RegisterComponentLibrary();
    RegisterHomepageLegacy();
    import("../libs/builder.setting");
  },
};

const NotFoundPage: React.FC = () => {
  let cmp;

  const registerComponent = (model: PreviewingModel) =>
    (componentMap[model] || componentMap.default)();

  registerComponent(Builder.previewingModel as PreviewingModel);

  if (Builder.isPreviewing || Builder.isEditing) {
    const model = builder?.previewingModel;

    switch (model) {
      case "page-full-nav":
        cmp = (
          <MainLayout>
            <BuilderComponent model={"page-full-nav"} />
          </MainLayout>
        );
        break;
      case "page-signup-form":
        cmp = (
          <div className={pageSignupStyles.container}>
            <AlternativeHeaderSignUpForm />
            <BuilderComponent model="page-signup-form" />
          </div>
        );
        break;
      case "page-condensed-nav":
        cmp = (
          <DefaultLayoutShortNav
            hasFooterLargeCta={false}
            isFixed
            isTracked
            hasNoFooterLinks
            hasNoHeader={false}
            globalFooterLargeH={{
              navCtaText: "Start for free",
              navCtaLink: "/sign-up/",
            }}
          >
            <BuilderComponent model="page-condensed-nav" />
          </DefaultLayoutShortNav>
        );
        break;
      case "page-resource-post":
        cmp = (
          <MainLayout>
            <BuilderComponent model="page-resource-post" />
          </MainLayout>
        );
        break;
      case "page":
        cmp = (
          <>
            <BuilderComponent model="page" />
          </>
        );
        break;
      default:
        cmp = (
          <MainLayout>
            <BuilderComponent model={"page-full-nav"} />
          </MainLayout>
        );
    }
  } else {
    cmp = (
      <MainLayout>
        <Seo title="Page not found" />
        <NotFoundContent />
      </MainLayout>
    );
  }
  return cmp;
};

export default NotFoundPage;
